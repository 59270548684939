import React, { Fragment, Suspense, lazy } from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

const firebaseConfig = {
  apiKey: "AIzaSyBfASZzjm_kTuJrziPQzm2K954OwkwpdMY",
  authDomain: "quadro-de-rotina.firebaseapp.com",
  databaseURL: "https://quadro-de-rotina-default-rtdb.firebaseio.com",
  projectId: "quadro-de-rotina",
  storageBucket: "quadro-de-rotina.appspot.com",
  messagingSenderId: "474370351956",
  appId: "1:474370351956:web:77fc8a925138a284a1dec1",
  measurementId: "G-ZV6EE1Y9CV"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <Switch>
              <Route>
                <LoggedOutComponent />
              </Route>
            </Switch>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
